import React, { Component } from "react";
import PropTypes from 'prop-types';
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
// import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap'

class HomeLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            page: props.data.dataJson,
            guests: 2,
            rooms: 1,
            arrival: new Date(),
            departure: new Date() + 1
        }
        // console.log(props);
    }
    componentDidMount() {
        var date = new Date();
        var arrival = date.getDate() + "/" + date.getMonth() + 1 + "/" + date.getFullYear();
        date.setDate(date.getDate() + 1);
        var departure = date.getDate() + "/" + date.getMonth() + 1 + "/" + date.getFullYear();
        this.setState({ arrival: arrival, departure: departure });

    }
    // {/* <span className="text-white caps_large underline-primary">middle-market private equity</span> */}
    render() {
        return (
            <Layout>
                <section className="header" style={{ backgroundColor: "#5A368A", height: '100vh'}} data-scrim-top="12">
                    <div className="container d-flex flex-column justify-content-center align-items-center">
                        <h1 className="text-white text-center"><strong>The Indus India Fund - Assisting in shaping the future - investing in Medtech / Life sciences, Fintech & AI to create impact.</strong></h1>
                        <span><Link to="/about-us" className="btn btn-large text-white bg-secondary rounded-md">Know More <i className="fa fa-long-arrow-right text-white"></i></Link></span>
                    </div>    
                </section>
                <section className="section py-7 bg-pale-primary lead p-5">
                    <div className="container py-7 text-center">
                        <h1>The Indus Thesis and Outlook</h1>
                        <p>India is the last major global economy currently enroute to a middle- income GDP per capita status. This transformative era ahead of us will reshape the world economically and geo-politically and open an unparalleled opportunity to create value and impact humanity. </p>
                        <p>This is a radical transformation under way and gathering momentum, with startups at the forefront leading the charge and redefining business models and driving unprecedented innovation. </p>
                        <p>The Indus India Fund 1 aims to be a part of this journey and make a significant contribution to it.</p>
                    </div>
                </section>
            </Layout>
        )
    }
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      subTitle
      titleBackground
      contents {
         heading
         image
         imageLeft
         content
         link
       }
    }
  }
`

HomeLayout.propTypes = {
    data: PropTypes.object
};

React.defaultProps = {
    className: 'react-remark',
};

export default HomeLayout;
